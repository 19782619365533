import SquareImage from '../global/SquareImage';
import Text from '../global/Text';

import img1 from '../../media/blackbird-recruitment-teamwork.webp';

const Section1 = () => {
  return (
    <section className="container py-5" data-aos="fade-up" data-aos-duration="800">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 mb-4 mb-lg-0">
          <SquareImage imgSrc={img1} altTxt={"Blackbird talent solutions & recruitment - Team full of diversity and inclusion."} />
        </div>
        <div className="col-12 col-lg-5 offset-lg-1">
          <Text
            heading={'Bringing back the human element to recruitment.'}
            lead={
              'We believe magic happens when the right people work together.'
            }
            paragraph={
              'Building motivated and high-performing teams is the top priority for leaders globally. Attracting the right hire can be both time-consuming and expensive - making the wrong hire even more so.'
            }
            paragraph2={"Don't worry, we've got you covered!"}
          />
        </div>
      </div>
    </section>
  );
};

export default Section1;