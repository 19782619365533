import Text from '../global/Text';
import CircleImage from '../global/Circles';
import hrimg from '../../media/blackbird-talent-solutions-recruitment-human-resources.webp';

const Section2 = () => {
    return (
        <section className="container py-5">
            <div className="row mb-3 mb-md-5">
                <div className="col-12 text-center">
                    <h2 className="h1">We are here for...</h2>
                </div>
            </div>
            <div className="row h-100 align-items-center">  
                <div className="col-12 col-lg-6 h-100 position-relative order-lg-2 mb-3 mb-lg-0" data-aos="fade-left" data-aos-duration="800">
                    <CircleImage imgSrc={hrimg} altTxt={"Blackbird Talent Solutions & Recruitment - we are here for human resources."}/>
                </div>
                <div className="col-12 col-lg-6 order-lg-1" data-aos="fade-right" data-aos-duration="800">
                    <Text 
                        heading={"Line Manager"} 
                        lead={"As your partner, we speak your language. With our corporate line experience, we help you identify the technical and human skills needed to find the right people for your team."}
                        paragraph={"We understand the demands of meeting deadlines and running operations. That's why we free up your valuable time by advising you on talent strategy, sharing market insights, and handling everything necessary to hire the right people."}
                        paragraph2={"If you have an HR team, we're happy to liaise with them to support you."}
                    />
                </div>
            </div>
        </section>
    )
}

export default Section2;