import { useState } from "react";
import ImageAndTextSquare from '../global/ImageAndTextSquare';
import styles from '../../style/textandimage.module.css';
import diversity from '../../media/diversity.webp';
import partial from '../../media/partial.webp';
import opinion from '../../media/opinion.webp';

const ServicesToggleImgAndTxt = () => {
    const [showMore, setShowMore] = useState(false);
    const [showMorePAS, setShowMorePAS] = useState(false);
    const [showMoreSOS, setShowMoreSOS] = useState(false);

    return (
      <>
        <div className="row">
            <div className={styles.firstImg + " col-12 col-lg-4 mb-5 mb-lg-0"} data-aos="fade-up" data-aos-duration="800">
                <ImageAndTextSquare 
                    imgSrc={diversity} 
                    altTxt="blackbird talent solutions & recruitment - diversity recruitment, executive search" 
                    heading="Executive Search"
                    lead="Unlock exceptional leadership."
                    paragraph="We embrace a modern approach to executive search, to find leaders who can help you build an organisation fit for the future."
                />
                {!showMore && <button className={styles.arrow} onClick={() => setShowMore(true)}>Read more</button>}
                {showMore ? 
                    <>
                        <p>As a forward thinking company you can not rely on old-fashioned methods to find future leaders.</p>  
                        <p>With extensive industry knowledge and a vast network, we connect you with inspiring, innovative leaders who drive success.</p>
                    </>
                    : null
                }
                {showMore && <button className={`${styles.arrow} ${styles.arrowUp}`} onClick={() => setShowMore(false)}>Collapse</button>}
            </div>
            <div className={styles.secondImg +  " col-12 col-lg-4 mb-5 mb-lg-0"} data-aos="fade-up" data-aos-duration="800">
                <ImageAndTextSquare 
                    imgSrc={partial} 
                    altTxt="blackbird talent solutions & recruitment - partial acquistion services, taior made solutions" 
                    heading="Tailor-made Solutions" 
                    lead="For a seamless recruitment process."
                    paragraph="Our ambition is to be as flexible as possible in supporting and guiding you through demanding situations."
                />
                {!showMorePAS && <button className={styles.arrow} onClick={() => setShowMorePAS(true)} title="read more">Read more</button>}
                {
                    showMorePAS ? 
                    <>
                        <p>If you already have a strong Talent Acquisition team, leverage our expertise by outsourcing various aspects of the recruitment process to us. Whether it's high-volume recruitment, RPO, meticulous screening, or strategic talent pooling, our flexible approach is designed to support you across multiple areas.</p>
                        <p>With our proactive talent pooling strategy, we maintain a pipeline of skilled professionals ready to integrate seamlessly into your team when needed.</p>
                    </>
                    : null
                } 
                {showMorePAS && <button className={`${styles.arrow} ${styles.arrowUp}`}  onClick={() => setShowMorePAS(false)}>Collapse</button>}
            </div>
            <div className={styles.thirdImg + " col-12 col-lg-4"} data-aos="fade-up" data-aos-duration="800">
                <ImageAndTextSquare 
                    imgSrc={opinion} 
                    altTxt="blackbird talent solutions - second opinion services" 
                    heading="Second Opinion"
                    lead="Objective insights, informed decisions."
                    paragraph="From our knowledge in personal and group dynamics, we can give valuable advice as support for you to select the right candidates."
                />
                {!showMoreSOS && <button className={styles.arrow} onClick={() => setShowMoreSOS(true)} title="read more">Read more</button>}
                {
                    showMoreSOS ? 
                    <>
                        <p>Through our quality-assured and validated solutions, Blackbird Recruitment can consult you as an objective third party in your selection process.</p>
                        <p>As your objective partner, we assess final candidates using proven tools and methods, aiding in informed decision-making.</p>
                        <p>Our collaboration extends to integrating new employees seamlessly into your organization, offering crucial advice throughout the process.</p>
                        <p>Our approach ensures that applicants are given a fair evaluation, and are provided with comprehensive feedback. You are provided with a solid input to make a well-educated decision.</p>
                    </>
                    : null
                }
                {showMoreSOS && <button className={`${styles.arrow} ${styles.arrowUp}`}onClick={() => setShowMoreSOS(false)}>Collapse</button>}

            </div>
        </div>
      </>
    );
  };
  
  export default ServicesToggleImgAndTxt;
  