import Employers from '../components/employers/Employers';
import TextAndCircles from '../components/employers/TextAndCircles';
import TextAndImage from '../components/employers/TextAndImage';

const EmployersPage = () => {
  return (
          <main>
            <Employers />
            <TextAndCircles 
              heading="We offer solutions through personnel analysis."
              lead="We strive to identify individuals who not only share your organization's values but also enrich your team culture; cultural addition."             
              paragraph2="Our talent search goes beyond assessing skills, experience, and references. We strive to understand each candidate’s uniqueness to ensure the best fit."
            />
            <TextAndImage />
          </main>
  );
}

export default EmployersPage;


