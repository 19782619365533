import FirstIntro from '../components/about/FirstIntro';
import SecondIntro from '../components/about/SecondIntro';
import ApplicantsUsp from '../components/job-seekers/ApplicantsUsp';
import Banner from '../components/global/Banner';
import styles from '../style/pages/about.module.css';
import arrow from '../media/arrow.svg';

function AboutUs() {
  return (
    <main>
      <div className="py-3 py-md-5 position-relative" data-aos="fade-up" data-aos-duration="800">
        <Banner 
          heading={"Our story."} 
          lead={"Inspired by Sweden's national bird, our name embodies not only our cultural roots and love for nature but also our belief in the power of communication and freedom symbolized by birds."} 
          paragraph={"After years in corporate roles, we realized our passion lies in fostering positive relationships and building strong, diverse teams. Blackbird was born from our commitment to empowering individuals, transforming the recruitment landscape, and contributing to society."}
        />
        <img src={arrow} width="35" height="35" className={styles.readMoreArrow + " d-none d-sm-block"} alt="scroll down to read more about us at blackbird talent solutions & recruitment"/>
      </div>
      <FirstIntro />
      <SecondIntro />
      <ApplicantsUsp />
    </main>
  );
}

export default AboutUs;
