import Diversity from "../components/services/Diversity";
import ServicesToggleImgAndTxt from '../components/global/ServicesToggleImgAndTxt';
import styles from '../style/textandimage.module.css';

const Services = () => {
  return (
    <main>
      <Diversity />
      <section className="container pt-3 pt-lg-5">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="h3">In addition to our full recruitment process, we offer the following services:</h2>
          </div>
        </div>
      </section>
      <section className={styles.textAndImageContainer + " container py-3 py-lg-5"}>
        <ServicesToggleImgAndTxt />
      </section>
    </main>
  );
}

export default Services;
