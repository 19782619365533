import bbird from '../../media/blackbird-recruitment-employers-coffe.webp';
import SquareImage from '../global/SquareImage';
import Text from '../global/Text';

const Employers = () => {
  return (
    <section className="container py-3 py-md-5">
      <div className="row align-items-center">
        <div className='col-12 col-lg-6 mb-4 mb-lg-0' data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
          <SquareImage imgSrc={bbird} altTxt="blackbird talent solutions & recruitment - desktop mobile plants" />
        </div>
        <div className='col-12 col-lg-5 offset-lg-1' data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000">
            <h1 className="h2 mb-3">Our expertise is focused on recruitment and talent management.</h1>
            <Text
                lead="We understand that the success of any business centers around its people; making the hiring process crucial." 
                paragraph="Finding and developing the right talent not only cuts costs but also strengthens your company culture, improves employee retention, and boosts overall performance." 
                paragraph2="Our goal is to provide a personalized service, priding ourselves on quality rather than quantity. That's why 96.43% of our candidates have stayed in the positions we placed them in for over a year." />            
        </div>
      </div>
    </section>
  );
};

export default Employers;
