import { useLocation } from 'react-router-dom';
import '../../style/global/navbar.css';
import logo from '../../media/logo-mini.png';

const Navbar = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="navbar">
      <div className="container">
        <a href="/"><img src={logo} alt="Blackbird Talent Solutions Logo" width="80" /></a>
        <button className="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#blackbird-navbar" aria-controls="blackbird-navbar" aria-label="Open navbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="blackbird-navbar" aria-labelledby="blackbird-navbar-label">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title mt-2" id="blackbird-navbar-label">Blackbird Talent Solutions</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="nav-list">
              <li className={isActive('/employers') ? 'active' : ''}><a href="employers">Employers</a></li>
              <li className={isActive('/job-seekers') ? 'active' : ''}><a href="job-seekers">Job Seekers</a></li>
              <li className={isActive('/services') ? 'active' : ''}><a href="services">Services</a></li>
              <li className={isActive('/about-us') ? 'active' : ''}><a href="about-us">About Us</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
