import ApplicantsHero from '../components/job-seekers/ApplicantsHero';
import ApplicantsIFrame from '../components/job-seekers/ApplicantsIFrame';
import ApplicantsUsp from '../components/job-seekers/ApplicantsUsp';

function JobSeekers() {
  return (
    <main>
      <ApplicantsHero />
      <ApplicantsIFrame />
      <ApplicantsUsp />;
    </main>
  );
}

export default JobSeekers;
