import SquareImage from '../global/SquareImage';
import Text from '../global/Text';
import ev from '../../media/blackbird-talent-solutions-evelyn-palma.webp';

const FirstIntro = () => {
    return (
      <section className="container py-3 py-md-5 mb-md-5">
        <div className="row align-items-center">
            <div className="col-12 col-lg-6 mb-4 mb-lg-0" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
                <SquareImage imgSrc={ev} altTxt={"Evelyn Palma - one of the founders of Blackbird Talent Solutions"} />
                <figcaption className="small mt-1 fw-bold">Evelyn, co-founder of Blackbird Talent Solutions®</figcaption>
            </div>
            <div className="col-12 col-lg-5 offset-lg-1" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000">
                <Text 
                    heading={"Bridging cultures."}
                    lead={"I was born in Chile and raised in Sweden. I have been working and living abroad since the beginning of my career."}
                    paragraph={"My multicultural background drives my passion for collaborating with others to create a fairer job market."}
                    paragraph2={"I am caring, trustworthy, open minded, and result-oriented. I appreciate creativity and enjoy exploring new ideas."}
                />
                <p>When not working or travelling, I love to spend time with friends and family, and to play video games.</p>
                <p className="signature">Evelyn Palma</p>
            </div>
        </div>
      </section>
    );
  }
  
export default FirstIntro;