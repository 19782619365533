import ServicesToggleImgAndTxt from "../global/ServicesToggleImgAndTxt";
import styles from '../../style/textandimage.module.css';

const TextAndImage = () => {
  return (
    <section className={styles.textAndImageContainer + " container py-3 py-lg-5"} >
      <div className="row mb-5">
          <div className="col-12 text-center">
              <h2 className="h1">Additional services</h2>
          </div>
      </div>
      <ServicesToggleImgAndTxt />
    </section>
  );
};

export default TextAndImage;
