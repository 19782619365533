import SquareImage from '../global/SquareImage';
import Text from '../global/Text';
import switzerland from '../../media/blackbird-recruitment-services.webp';

const Diversity = () => {
  return (
    <section className="container py-5">
      <div className="row align-items-center">
        <div className="col-12 col-lg-5" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
          <h1 className="h2 mb-3">Your trusted partner for specialized recruitment.</h1>
          <Text
            lead="We specialize in recruitment in the areas of Finance & Accounting, Human Resources, Supply Chain, and Sales in Switzerland and abroad."
            paragraph="Our consultants are based in Zurich and have a corporate background in different fields, allowing them to understanding technical requirements as well as company fit."
          />
        </div>
        <div className="col-12 col-lg-6 offset-lg-1 mb-3 mb-lg-0" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000">
          <SquareImage imgSrc={switzerland} altTxt="blackbird talent solutions & recruitment - switzerland alps nature flowers tree" />
        </div>
      </div>
    </section>
  );
};

export default Diversity;