import Text from '../global/Text';
import CircleImage from '../global/Circles';
import lineimg from '../../media/blackbird-talent-solutions-recruitment-line-manager.webp';

const Section3 = () => {
    return (
        <section className="container py-3">
            <div className="row h-100 align-items-center">
                <div className="col-12 col-lg-6 h-100 position-relative mb-3 mb-lg-0" data-aos="fade-right" data-aos-duration="800">
                    <CircleImage imgSrc={lineimg} altTxt={"Blackbird Talent Solutions & Recruitment - we are here for line management."}/>
                </div> 
                <div className="col-12 col-lg-5 offset-lg-1" data-aos="fade-left" data-aos-duration="800">
                    <Text 
                        heading={"HR"} 
                        lead={"As your external talent partner, we will support you and your recruitment team with everything you need to attract, hire, onboard, and retain talent."}
                        paragraph={"We understand that the critical nature of the HR position requires running several projects simultaneously and prioritizing recruitment efforts can be challenging."}
                        paragraph2={"Based on our professional experience and recruitment expertise, we not only speak your language but also understand the needs of your line managers. This makes us a great partner and an extended HR arm."}
                    />
                </div>
            </div>
        </section>
    )
}

export default Section3;