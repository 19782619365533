import Text from '../global/Text';
import CircleImage from '../global/Circles';
import talentimg from '../../media/blackbird-recruitment-diverse-talent.webp';

const Section4 = () => {
    return (
        <section className="container py-5 mb-lg-4">
            <div className="row h-100 align-items-center"> 
                <div className="col-12 col-lg-6 offset-lg-1 order-lg-2 mb-3 mb-lg-0" data-aos="fade-left" data-aos-duration="800">
                    <CircleImage imgSrc={talentimg} altTxt={"Blackbird Talent Solutions & Recruitment - we are here for diverse talent."}/>
                </div>
                <div className="col-12 col-lg-5 order-lg-1" data-aos="fade-right" data-aos-duration="800">
                    <Text 
                        heading={"Diverse Talent"} 
                        lead={"Finding a new job isn't easy. Whether you're actively searching or just exploring opportunities, we understand the time and effort required for your applications."}
                        paragraph={" Perhaps you haven't updated your CV in years, or you're considering opportunities abroad. You might even feel discouraged by unrealistic job requirements in the market."}
                        paragraph2={"We are here to support you every step of the way. We'll represent you fairly and continuously seek ways to enhance your candidate experience."}
                    />
                </div>
            </div>
        </section>
    )
}

export default Section4;