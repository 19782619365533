import SquareImage from '../global/SquareImage';
import Text from '../global/Text';
import img from '../../media/blackbird-recruitment-applicants.webp';

const ApplicantsHero = () => {
    return (
      <section className="container py-3 py-lg-5">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0" data-aos="fade-right" data-aos-duration="800">
            <SquareImage imgSrc={img} altTxt={"Blackbird Talent Solutions & Recruitment - applications"} />
          </div>
          <div className="col-12 col-lg-5 offset-lg-1" data-aos="fade-left" data-aos-duration="800">
            <h1 className="h2 mb-3">Job seekers</h1>
            <Text 
                lead={"We partner with companies across various industries in Switzerland and throughout Europe."}
                paragraph={"Our goal is to make your recruitment journey as efficient as possible. When we collaborate with you on a recruitment process we provide support every step of the way. This includes:"}
            />
            <ul>
              <li>A personal discussion to understand your aspirations</li>
              <li>CV advice</li>
              <li>Interview preparation</li>
              <li>Offer negotiation</li>
              <li>Post placement integration</li>
            </ul>
            <p>We warmly welcome you to submit your profile to our talent community/pool for the areas we specialize in below.</p>
          </div>
        </div>
      </section>
    );
  }
  
  export default ApplicantsHero;
  