import ImageAndTextSquare from '../global/ImageAndTextSquare';
import img1 from '../../media/blackbird-recruitment-be-brave.webp';
import img2 from '../../media/blackbird-recruitment-be-inclusive.webp';
import img3 from '../../media/blackbird-recruitment-be-respectful.webp';
import img4 from '../../media/blackbird-recruitment-be-dedicated.webp';
import styles from '../../style/pages.module.css';

const ApplicantsUsp = () => {
    return (
      <section className="container pb-5 mb-md-5">
        <div className="row">
            <div className="col text-center" data-aos="fade-up" data-aos-delay="300">
                <h2 className="h1 mb-4">Be blackbird</h2>
            </div>
        </div>
        <div className="row">
            <div className={styles.applicantsUsp + " col-12 col-md-6 col-lg-3"} data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <ImageAndTextSquare imgSrc={img2} heading={"Brave"} altTxt={"Be brave - man holding up woman"}/>
            </div>
            <div className={styles.applicantsUsp + " col-12 col-md-6 col-lg-3"} data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
                <ImageAndTextSquare imgSrc={img4} heading={"Inclusive"} altTxt={"Be inclusive - girl with freckles smiling"}/>
            </div>
            <div className={styles.applicantsUsp + " col-12 col-md-6 col-lg-3"} data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000">
                <ImageAndTextSquare imgSrc={img3} heading={"Respectful"} altTxt={"Be respectful - woman and man, smiling while working in an office"}/>
            </div>
            <div className={styles.applicantsUsp + " col-12 col-md-6 col-lg-3"} data-aos="fade-up" data-aos-delay="900" data-aos-duration="1000">
                <ImageAndTextSquare imgSrc={img1} heading={"Dedicated"} altTxt={"Be dedicated - girl with big beautiful hair, giggling"}/>
            </div>
        </div>
      </section>
    );
  }
  
export default ApplicantsUsp;