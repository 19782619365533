import SquareImage from '../global/SquareImage';
import Text from '../global/Text';
import p from '../../media/blackbird-talent-solutions-peter-sand.webp';

const SecondIntro = () => {
    return (
      <section className="container py-5 mb-md-5">
        <div className="row align-items-center">
            <div className="col-12 col-lg-5 order-2 order-lg-1" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
                <Text 
                    heading={"Empowering through experience."}
                    lead={"I have been living and working in four countries over the past 20 years."}
                    paragraph={"My passion is about understanding people from different backgrounds, and supporting them to improve both their careers and personal life."}
                    paragraph2={"I'm open, direct, energetic and thoughtful, with a great sense of humour and an optimistic mindset."}
                />
                <p>When not working, you can usually find me along hiking trails across Europe.</p>
                <p className="signature">Peter Sand</p>
            </div>
            <div className="col-12 col-lg-6 offset-lg-1 order-1 order-lg-2 mb-4 mb-lg-0" data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000">
                <SquareImage imgSrc={p} altTxt={"Peter Sand - one of the founders of Blackbird Talent Solutions"} />
                <figcaption className="small mt-1 fw-bold">Peter Sand, co-founder of Blackbird Talent Solutions®</figcaption>
            </div>
        </div>
      </section>
    );
  }
  
export default SecondIntro;